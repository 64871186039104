import React from "react"
import { Link } from "gatsby"
import { motion } from "framer-motion"
import styles from "./Heading.module.scss"
import { variants } from "../../helpers/motionVariants"

export default props => {
    const signpostLink = props.signpostLinkText ? (
        <Link to={props.signpostLinkPath} className={styles.heading__link}>
            {props.signpostLinkText}
        </Link>
    ) : null

    const returnLink = props.returnLink ? (
        <Link to="/work/" className={styles.heading__link}>
            Return to Work Index
        </Link>
    ) : null

    const HeadingDescriptionContent = props.headingDescription ? (
        <span className={styles.heading__description}>
            {props.headingDescription}
            {props.signpostLinkText || props.returnLink ? " - " : null}
            {signpostLink}
            {returnLink}
        </span>
    ) : null

    const HeadingTag = props.headingLevel ? `h${props.headingLevel}` : `h2`

    return (
        <motion.header
            className={styles.heading}
            variants={variants}
            initial="hidden"
            animate="enter"
        >
            <HeadingTag
                className={`${styles.heading__title} ${
                    props.headingLevel === "2"
                        ? styles.heading__title____signpost
                        : null
                }`}
            >
                {props.headingTitle}
            </HeadingTag>

            {HeadingDescriptionContent}
        </motion.header>
    )
}
