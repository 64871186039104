import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Helmet from "react-helmet"

export default props => {
    const data = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                    }
                }
            }
        `
    )
    const metadata = data.site.siteMetadata

    return (
        <Helmet defer={false}>
            <html lang="en" />
            <meta charSet="utf-8" />
            <title>{`${props.title} - ${metadata.title}`}</title>
            <meta
                name="description"
                content="The portfolio of front-end designer and developer Glynn Smith"
            />
            <link rel="canonical" href="https://www.iamglynnsmith.com/" />
            <meta
                name="viewport"
                content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
            />
        </Helmet>
    )
}
