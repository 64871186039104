const duration = 0.5

export const variants = {
    hidden: {
        opacity: 0,
        transition: {
            duration: duration,
        },
    },
    enter: {
        opacity: 1,
        transition: {
            duration: duration,
            delay: duration / 4,
        },
    },
}

export const variantsPrimary = {
    hidden: {
        opacity: 0,
        y: "10px",
        transition: {
            duration: duration,
        },
    },
    enter: {
        opacity: 1,
        y: 0,
        transition: {
            duration: duration,
        },
    },
}

export const variantsSecondary = {
    hidden: {
        opacity: 0,
        y: "20px",
        transition: {
            duration: duration,
            delay: duration / 2,
        },
    },
    enter: {
        opacity: 1,
        y: 0,
        transition: {
            duration: duration,
            delay: duration / 2,
        },
    },
}

export const variantsTertiary = {
    hidden: {
        opacity: 0,
        y: "20px",
        transition: {
            duration: duration * 2,
            delay: duration,
        },
    },
    enter: {
        opacity: 1,
        y: 0,
        transition: {
            duration: duration * 2,
            delay: duration,
        },
    },
}

export const variantsHeroMain = {
    hidden: {
        opacity: 0,
        x: "-10%",
    },
    enter: {
        opacity: 1,
        x: "0%",
        transition: {
            duration: duration * 6,
            delay: duration * 1.5,
        },
    },
}

export const variantsHeroSub = {
    hidden: {
        opacity: 0,
        x: "-10%",
    },
    enter: {
        opacity: 1,
        x: "0%",
        transition: {
            duration: duration * 6,
            delay: duration * 2,
        },
    },
}
